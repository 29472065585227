import {
  Routes,
  Route,
  Navigate,
  BrowserRouter as Router,
} from "react-router-dom";
import React from "react";

import Apb from "./views/pages/apb";
// import Identification from "./views/pages";
// import ApbCertificate from "./views/pages/ApbCertificate";

// import Riskcovry from "./views/pages/riskcovry";

// import DmiFinance from "./views/pages/medicalmobile";

import StatusPage from "./views/statuspage";

// import LandingPage from "./views/pages/dimsmobile/LandingPage";
// import ProtectForm from "./views/pages/dimsmobile/ProtectForm";
// import PaymentLoading from "./views/pages/dimsmobile/PaymentLoading";
// import PaymentSuccess from "./views/pages/dimsmobile/PaymentSuccess";
// import PaymentFailure from "./views/pages/dimsmobile/PaymentFailure";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route exact path="/" element={<Identification />} /> */}

        {/* ****** Apb Page ****** */}
        <Route exact path="/" element={<Apb />} />
        {/* <Route exact path="/apb-certificate" element={<ApbCertificate />} /> */}

        {/* ****** Upload Successful Page ****** */}
        <Route path="/statusPage" element={<StatusPage />} />

        {/* ****** Riskcovry Page ****** */}
        {/* <Route exact path="/" element={<Riskcovry />} /> */}

        {/* ****** DMI Mobile Page ****** */}
        {/* <Route path="/" element={<LandingPage />} />
        <Route path="/protect-form" element={<ProtectForm />} />
        <Route path="/payment-process" element={<PaymentLoading />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-failure" element={<PaymentFailure />} /> */}

        {/* ****** DMI Finance Page ****** */}
        {/* <Route exact path="/" element={<DmiFinance />} /> */}

        {/* <Route path="/payment-success" element={<PaymentSuccessPage />} /> */}

        {/* When we enter wrong route */}
        <Route path="*" element={<Navigate to="/" />} />
        {/* <Route path="*" element={<Navigate to="/apb-certificate" />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
// ********** Test Paymnent Code 21 September **********
// import axios from "axios";
// import React from "react";
// import { Button } from "react-bootstrap";
// import { DMI_FINANCE_APIS } from "./config/ApiConfig";
// import { useEffect } from "react";

// const App = () => {
//   const textPaymentGateway = async () => {
//     // const paymentData2 = {
//     //   merchant_id: "265843",
//     //   order_id: "Ord_498",
//     //   amount: "649",
//     //   currency: "INR",
//     //   redirect_url: "http://localhost:3000",
//     //   cancel_url: "http://localhost:3000",
//     //   accessCode: "ATUT05LI94BL95TULB",
//     //   workingKey: "DD55103DB563EA52FB9F3FB7F09E3A54",
//     //   // redirect_url: "http://localhost:3001/success",
//     //   // cancel_url: "http://localhost:3001/cancel",
//     //   billing_name: "test",
//     //   billing_address: "test",
//     //   billing_city: "MUMBAI",
//     //   billing_state: "MAHARASHTRA",
//     //   billing_zip: "400001",
//     //   billing_country: "India",
//     //   billing_tel: "9503678892",
//     //   billing_email: "test@test.com",
//     //   encRequest:
//     //     "b4abf9cbe4923f9dfa7d50516aefd40c27f34e5a22922c52a4bbc17b73946263253e69f541799d7db14f4748617a85e8975547bf6921736127d251f6f6149b6f338991bbdb37f7f2f54196f152a07e0618845ecefba3410563a68251a112c1515c1d57d0c2200274e27ba86833d9f82dc2e249f4cf517e705977953a38375bff333bda630789c3cc65d12dc1334c043cf3061bcf090d82deaf83f92a97d9abd22ec0826db36f983fef807bee529a46df42b6b693ffd7662cbc41d80df8a5d226a459bb955f39f779076cdaf4f87e2a91f509945b67e70abb0f7a1b54db29b9228ce0fb112dd491860deb886cc510379e335efca6915845f8c25df3dc889767c42a29fab67efa605f2baa1e629e94b221",
//     // };

//     const paymentData =
//       "merchant_id=265843&order_id=Ord_498&amount=649&currency=INR&redirect_url=&cancel_url=&billing_name=test&billing_address=test&billing_city=MUMBAI G.P.O.&billing_state=MAHARASHTRA&billing_zip=400001&billing_country=India&billing_tel=9503678892&billing_email=test@test.com";
//     console.log("85 ===", paymentData);

//     axios({
//       method: "POST",
//       // url: "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction",
//       url: "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=6bb852d2a0295bdfd556f8a029c2a86d7ca2ec7870cb7b9fd4d96d5add8e7b32eb0845e91fb4cf5188b33812b77d70ab9a1f2fb65d7ba3684e3161a2c20f4ffd74a5363593d81e2328edc2bee751997d74aabef5bd1e60b36ea6c38077c36361a68b91cb06f077b61bc9792f39ccc29abbc444c3143f3ab53a3f172fd002f47fed5f07a2d49fd3b5c34d2ca5add09964f016230242080b92ec803a2ee24d22e6f2a9eb9e8c32381ee3b4290c11b723a5fc5d2f2992f60c6b3887dc1cf707ded9f31f80f72e9773888bd9e32c90e5957f68ec2d71e46ad4043f60c018d478a2595724ae64c1a3e7bddc774183fb2b8a2246785fb6d07cca60e8719154aa7d330c245199e3c60557470ef44e65d957a36bc52a79e8be1db6fa4833e580fdcb4f95e7ec2da09e0d28ef545a1e411c57939f34d0ae129843e9278efac0adecc6b5e91365d3a9918c5f2deaffe40ae5b538da2956511128b43952df4b74d124caa6573210747ea26962c27549631d9650f053f0ad7c7a780dffde8d281b19c9f3a69ebeea8decf3d6a3ec3cec43fdcfe2c06d5c88cdd7193d1d814ba68be73f2521725c3b12c2c18c5a20c18ca141fe0237d3aaef535541eba37a7075e8f62547052b6d5ea85a3a3b11ca32c0c92d647615ba&access_code=ATUT05LI94BL95TULB",
//       // data: paymentData,
//       // headers: { "Content-Type": "application/json" },
//     })
//       .then((res) => {
//         console.log("94 ==== res ====", res);
//         // Dynamically create a form and submit it
//         // const newWindow = window.open();
//         // newWindow.document.write(html);
//       })
//       .catch((e) => {
//         console.log("100 ==== error ====", e);
//       });
//     // const response = await fetch("http://localhost:3001", {
//     // const response = await fetch(
//     //   "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction",
//     //   {
//     //     method: "POST",
//     //     headers: {
//     //       "Content-Type": "application/json",
//     //     },
//     //     body: JSON.stringify(paymentData),
//     //   }
//     // );
//     // console.log("92 ==== Resp ====", response);
//     // const html = await response.text();
//     // console.log("95 ==== html ====", html);
//     // Dynamically create a form and submit it
//     // const newWindow = window.open();
//     // newWindow.document.write(html);
//   };

//   const openUrlLink = () => {
//     window.open(
//       "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=6bb852d2a0295bdfd556f8a029c2a86d7ca2ec7870cb7b9fd4d96d5add8e7b32eb0845e91fb4cf5188b33812b77d70ab9a1f2fb65d7ba3684e3161a2c20f4ffd78ad0b9f460ec9a97452a4fa82d9bbbfa1dbb4f6ba1707304cf9ba13747cb5294ff884e0a42721b3ba014f650da66c5c0393e1830c9105cbb412369c94d6f29ff7be636d7a34c9fe1db9f3e02c8fa7a0b4e5b3f21d85ed42c7a9726ff955bcc1ccff095bba38487b59fd03ae992150021c8ca95b6ada8f6156ac2ea21c88f246130673ed167af597d65c65d30f85485bc043b10fd80dc9f46b71de054ca060b3c8ca075bc8b1f6ced4e71a8af26876b93b9a31d06a3d176549064a6bcba950beca94ce074db70380d7011d083849deaaf7b22cb523606f3759e2cbc8692931074c31e4bc8071599543f0c8ced12e4307b9f495bed31c3ff4a6b9deeb73f3a8d98d14952104e93aef85cff2083c11c05257d64fcefe85692d41d8003104247a89204c496097f7d4256fb1b0460cd055c116e4e030b1955b114269981c2a8ccad148e43a919baf4bb9b78bba3c9568b3fc8fe3936477ac5c5c821e58af4d454329f944ea3e302e36925328bf1630975419&access_code=ATUT05LI94BL95TULB",
//       "_blank"
//     ); // Opens in a new tab
//   };

//   // encResp

//   useEffect(() => {
//     getUrlApi();
//   });

//   const getUrlApi = async () => {
//     // const encps =
//     //   "3d5c4a5a2cb67c2ddac57b1c418c11aedcb63aadda1bdd1907ac00b3faaa3be7eaa8c27bdfa78c7a5abfd673710ec3562fc4a88d4ec4a22b3967ac635f6fbd14bbc1a72ae0037f81b21f1bc02132cb5ac14cc351b8716bbda336c80c8d0967fcc251daefa689230c745ed43ab91ddc4283c88053789a631591cc3f2908db487c4041bd51591ed541b663ffeafdc64d5fbd6584d9ab88d84d4310ad9ff516106d7bfa3eb505f4d05862ea303d4cefac1c5a9dad7adb74e6a864521c2d67d0694218b94387501c1b16731d0be94a050dd6e8b0adaa6741a627f7b68de1590dfe33af731f5041e2ea6b2d01d2e6eb8247343fe452bc827ef0cd3233fa8f51f2defb3e439962845b9d40ed8e4a57125f4be432f802688323b73f52e6a3b969fa0401c9a4eded2d65931db9d310c07f459b09ec50969649ba102d578d0669c0aceb9a14a97384a63a8a8a519d1d7d6fa2c5c83ff8510a6edbf399f4f7b58058d3dbe43a1891d6dcba8a76c42581dca6d63f5f2a4f70c0b964e750d5d0829129e5964e48bd9aae852a24f135b783377db7fa488db7a063845fe570c4d9e3cc49411514417678d2cfb0e602e708e1683babd4c905791d5615531590283d8a89383d5e51261dc073ab8f43874109ede03760f6975a4a8b6d6427d2124f30c6577246ebcb117723b5ff1b1be9336eeac3e943cfd347d740234e334b400f80725178d08b4997af407fc03c116a4a3269e9afe96107aa57b611014a9410cf41eb8b1abd6a86b0d29356bebd542df7b52986f6e13bd346dcde9ba5c63f10d619da25e77c2cd63149db86ee403249fcb9f467814003fab783f68487c9e9713730f920a34d00663cbe92d78d08027226053407aca4767c419756a79be2c40ce924a56ce114859dae95dce551da59b931978fd73738819492856e0dae066ace8794c7b11c898a4160ccf86c011c3bb5b866138399b05bef74fc663c35eb8e935429854aa289d3d1ce794d4aa45f88d47073fe5757461341619121186aca515aaff4736dea08a1134d46efbfa02f7f14eb67099706c4ad0c1ab92ac8f5041c2510fd4f13b252a6a35162bc4a415181b923766f3683aa569d6a82cfaaf991d82e400e36988d0fee2fa3021a37d1a6b8268a9ed77cda6812ceeaaa84c08aaff18b56776f1d21fd4c82";

//     axios(
//       `https://portal.acrossassist.com/api/partner/v1/dmi/GetUrl?encResp=3d5c4a5a2cb67c2ddac57b1c418c11aedcb63aadda1bdd1907ac00b3faaa3be7eaa8c27bdfa78c7a5abfd673710ec3562fc4a88d4ec4a22b3967ac635f6fbd14bbc1a72ae0037f81b21f1bc02132cb5ac14cc351b8716bbda336c80c8d0967fcc251daefa689230c745ed43ab91ddc4283c88053789a631591cc3f2908db487c4041bd51591ed541b663ffeafdc64d5fbd6584d9ab88d84d4310ad9ff516106d7bfa3eb505f4d05862ea303d4cefac1c5a9dad7adb74e6a864521c2d67d0694218b94387501c1b16731d0be94a050dd6e8b0adaa6741a627f7b68de1590dfe33af731f5041e2ea6b2d01d2e6eb8247343fe452bc827ef0cd3233fa8f51f2defb3e439962845b9d40ed8e4a57125f4be432f802688323b73f52e6a3b969fa0401c9a4eded2d65931db9d310c07f459b09ec50969649ba102d578d0669c0aceb9a14a97384a63a8a8a519d1d7d6fa2c5c83ff8510a6edbf399f4f7b58058d3dbe43a1891d6dcba8a76c42581dca6d63f5f2a4f70c0b964e750d5d0829129e5964e48bd9aae852a24f135b783377db7fa488db7a063845fe570c4d9e3cc49411514417678d2cfb0e602e708e1683babd4c905791d5615531590283d8a89383d5e51261dc073ab8f43874109ede03760f6975a4a8b6d6427d2124f30c6577246ebcb117723b5ff1b1be9336eeac3e943cfd347d740234e334b400f80725178d08b4997af407fc03c116a4a3269e9afe96107aa57b611014a9410cf41eb8b1abd6a86b0d29356bebd542df7b52986f6e13bd346dcde9ba5c63f10d619da25e77c2cd63149db86ee403249fcb9f467814003fab783f68487c9e9713730f920a34d00663cbe92d78d08027226053407aca4767c419756a79be2c40ce924a56ce114859dae95dce551da59b931978fd73738819492856e0dae066ace8794c7b11c898a4160ccf86c011c3bb5b866138399b05bef74fc663c35eb8e935429854aa289d3d1ce794d4aa45f88d47073fe5757461341619121186aca515aaff4736dea08a1134d46efbfa02f7f14eb67099706c4ad0c1ab92ac8f5041c2510fd4f13b252a6a35162bc4a415181b923766f3683aa569d6a82cfaaf991d82e400e36988d0fee2fa3021a37d1a6b8268a9ed77cda6812ceeaaa84c08aaff18b56776f1d21fd4c82`,
//       {
//         method: "GET",
//         headers: { "Content-Type": "application/json" },
//       }
//     )
//       .then((res) => {
//         console.log("142 ==== resp ====", res);
//       })
//       .catch((e) => {
//         console.log("145 ==== error ====", e);
//       });
//   };

//   return (
//     <div>
//       <Button className="p-4" variant="secondary" onClick={openUrlLink}>
//         Open CC Avenue Payment Page
//       </Button>
//     </div>
//   );
// };

// export default App;
