import axios from "axios";
import * as yup from "yup";
import * as formik from "formik";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import { Row, Col, Card, Dropdown } from "react-bootstrap";

import "../styles.css";
import { X_API_KEY } from "../../../constants";
import Loader from "../../../components/Loader";
import AcrossLogo from "../../../assets/images/AcrossLogo.png";
import HelperImage from "../../../assets/images/HelperImage.png";
import AirtelPaymentBank from "../../../assets/images/AirtelPaymentBank.png";

function Apb(props) {
  const { Formik } = formik;
  const navigate = useNavigate();

  const [loadedUrl, setLoadedUrl] = useState("");

  useEffect(() => {
    setLoadedUrl(window.location.href);
  }, []);

  // let url = loadedUrl;
  // url = url.split("/");

  const [apbBrands, setApbBrands] = useState("");
  const [apbModel, setApbModel] = useState("");
  const [loading, setLoading] = useState(false);
  const [bppsTaxIdList, setBppsTaxIdList] = useState([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [deviceBase64Image, setDeviceBase64Image] = useState("");
  const [selectedValue, setSelectedValue] = useState("Select an option");

  const handleSelect = (eventKey) => {
    setSelectedValue(eventKey);
  };

  const onChangeDevicePhoto = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setDeviceBase64Image(reader.result);
      };
      reader.onerror = () => {
        // setUploadDevicePhotoError(true);
      };
    }
  };

  const initialValues = {
    searchQuery: mobileNumber,
    name: "",
    email: "",
    brand: apbBrands,
    model: apbModel,
    imeiNumber: "",
    invoicePhoto: "",
    devicePhoto: "",
  };
  const schema = yup.object().shape({
    searchQuery: yup
      .string()
      .required("Mobile number is required to search")
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
    // vpsTaxId: yup.string().required("Please select an option"),
    name: yup
      .string()
      .matches(/^[A-Za-z ]+$/, "Name should only contain letters and 1 space")
      .min(2, "Name should be at least 2 characters long")
      .max(60, "Name should not exceed 60 characters")
      .required("Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      ),
    imeiNumber: yup
      .string()
      .matches(/^[0-9]{15}$/, "Invalid IMEI number")
      .required("IMEI number is required"),
  });

  // ****** Search BppsTxId By Mobile Api ******
  const searchTByMobileApi = (searchQuery) => {
    axios(
      `https://portal.acrossassist.com/api/partner/v1/apb/GetBppsTaxIdByMobileNumber?CustomerMobileNumber=${searchQuery}`,
      {
        method: "GET",
        headers: { "x-api-key": X_API_KEY, "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setBppsTaxIdList(res?.data?.data);
        }
        if (res?.data?.statusCode === 400) {
          toast.error(res?.data?.msg);
        }
        if (res?.data?.statusCode === 404) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((e) => {});
  };

  // ****** Get Details BppsTxId By Mobile Api ******
  const getBrandAndModelByBppsTaxIdApi = (refId) => {
    const DATA = { BBPSTaxRefId: refId };

    axios(
      "https://portal.acrossassist.com/api/partner/v1/apb/GetBrandAndModelByBppsTaxId",
      {
        method: "POST",
        data: DATA,
        headers: {
          "x-api-key": X_API_KEY,
        },
      }
    )
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setApbBrands(res?.data?.data?.brands);
          setApbModel(res?.data?.data?.model);
        }
        if (res?.data?.statusCode === 400) {
          toast.error(res?.data?.msg);
        }
        if (res?.data?.statusCode === 404) {
          toast.error(res?.data?.msg);
        }
      })
      .catch((e) => {});
  };

  // ****** Upload Validation ******
  const handleSubmit = (values) => {
    if (deviceBase64Image === "") {
      toast.error("Please select upload screen image.");
    } else {
      uploadDetailsApi(values);
    }
  };

  // ****** Upload Form Api ******
  const uploadDetailsApi = async (values) => {
    setLoading(true);

    const DATA = {
      Email: values?.email,
      BrandName: values?.brand,
      ModelName: values?.model,
      UserName: values?.name,
      MobileNumber: values?.searchQuery,
      DeviceImage: deviceBase64Image,
      imeiOne: values?.imeiNumber,
    };

    axios(
      "https://portal.acrossassist.com/api/partner/v1/apb/SaveAirtelPaymentBankDetails",
      {
        method: "POST",
        data: DATA,
        headers: {
          "x-api-key": X_API_KEY,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res?.status === 200) {
          setDeviceBase64Image("");
          navigate("/statusPage");
          setLoading(false);
        } else {
          toast.error("Please validate your form and Try again !");
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        toast.error("Form not submitted. Please try again");
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="auth-wrapper text-center">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div className="auth-content">
            <Card
              className="borderless text-center"
              // class="col-xxl-1 col-lg-1 col-md-1 col-sm-1 col-1"
              style={{ borderRadius: 8 }}
            >
              <Card.Body>
                <div
                  className="mb-4 d-row"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div
                    style={{
                      width: "50%",
                      textAlign: "left",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={AirtelPaymentBank}
                      style={{ width: "70%" }}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      width: "45%",
                      // textAlign: "right",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={AcrossLogo}
                      style={{ width: "100%", marginTop: 20 }}
                      alt=""
                    />
                  </div>
                </div>

                <Formik
                  validationSchema={schema}
                  initialValues={initialValues}
                  validator={() => ({})}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <div>
                        <p style={{ color: "red", textAlign: "start" }}>
                          *Ensure the name matches the device owner name.
                        </p>
                      </div>

                      {/* ****** Search Container ****** */}
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormikUsername"
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Mobile
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              type="text"
                              placeholder="Search by mobile number"
                              aria-describedby="inputGroupPrepend"
                              name="searchQuery"
                              // value={values.searchQuery || mobileNumber}
                              value={mobileNumber}
                              onChange={(e) => {
                                handleChange(e);
                                setMobileNumber(e?.target.value);
                                searchTByMobileApi(e?.target.value);
                              }}
                              // isInvalid={!!errors.searchQuery || !!mobileNumber}
                              isInvalid={!!errors.searchQuery}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                width: "100%",
                                textAlign: "start",
                                flexDirection: "row",
                              }}
                            >
                              {errors.searchQuery}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>

                      {/* ****** Dropdown Container ****** */}
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormikDropdown"
                          // controlId="validationFormikUsername"
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            BBPS Tax ID
                          </Form.Label>

                          <InputGroup hasValidation>
                            <Dropdown
                              data-bs-theme="dark"
                              onSelect={(e) => handleSelect(e)}
                            >
                              <Dropdown.Toggle
                                id="dropdown-button-dark-example1"
                                variant=""
                                style={{
                                  width: "100%",
                                  borderWidth: 1,
                                  display: "flex",
                                  alignItems: "center",
                                  borderColor: "lightgrey",
                                  backgroundColor: "#f5f7fa",
                                  justifyContent: "space-between",
                                }}
                              >
                                {selectedValue
                                  ? selectedValue
                                  : "Select an options"}
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                style={{
                                  borderWidth: 1,
                                  borderColor: "#ced4da",
                                  backgroundColor: "#f8f9fa",
                                }}
                              >
                                {bppsTaxIdList?.map((item) => {
                                  return (
                                    <Dropdown.Item
                                      onClick={() =>
                                        getBrandAndModelByBppsTaxIdApi(
                                          item?.bbpsTaxRefId
                                        )
                                      }
                                      eventKey={item?.bbpsTaxRefId}
                                    >
                                      {item?.bbpsTaxRefId}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            </Dropdown>

                            <formik.ErrorMessage
                              name="dropdownField"
                              component="div"
                              className="text-danger mt-2"
                            />
                          </InputGroup>
                        </Form.Group>
                      </Row>

                      {/* ****** Name Container ****** */}
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormikUsername"
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Name
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              type="text"
                              placeholder="Name"
                              aria-describedby="inputGroupPrepend"
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              isInvalid={!!errors.name}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                width: "100%",
                                textAlign: "start",
                                flexDirection: "row",
                              }}
                            >
                              {errors.name}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>

                      {/* ****** Email Container ****** */}
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlID="formGridEmail">
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Email
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              type="email"
                              placeholder="Email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              isInvalid={!!errors.email}
                              // errors={errors}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                width: "100%",
                                textAlign: "start",
                                flexDirection: "row",
                              }}
                            >
                              {errors.email}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>

                      {/* ****** Mobile Container ****** */}
                      {/* <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="formGridPhone">
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Mobile
                          </Form.Label>
                          <Form.Control
                            type="mobile"
                            placeholder="Mobile"
                            name="mobileNumber"
                            value={values.mobile}
                            onChange={handleChange}
                            readOnly
                            // isInvalid={!!errors.mobile}
                          />
                        </Form.Group>
                      </Row> */}

                      {/* ****** Brand Container ****** */}
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormik02"
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Brand
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="brand"
                            value={apbBrands}
                            readOnly
                            // isValid={touched.brand && !errors.brand}
                          />
                        </Form.Group>
                      </Row>

                      {/* ****** Model Container ****** */}
                      <Row className="mb-3">
                        <Form.Group as={Col} md="12">
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Model
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Model"
                            name="model"
                            value={apbModel}
                            readOnly
                          />
                        </Form.Group>
                      </Row>

                      {/* ****** IMEI 1 Container ****** */}
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormikImei"
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                              // color: "black",
                              // fontSize: 15,
                              // fontWeight: "600",
                            }}
                          >
                            IMEI 1
                          </Form.Label>
                          <InputGroup hasValidation>
                            <Form.Control
                              type="text"
                              placeholder="IMEI Number"
                              aria-describedby="inputGroupPrepend"
                              name="imeiNumber"
                              value={values.imeiNumber}
                              onChange={handleChange}
                              isInvalid={!!errors.imeiNumber}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                width: "100%",
                                textAlign: "start",
                                flexDirection: "row",
                              }}
                            >
                              {errors.imeiNumber}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>

                      {/* ****** IMEI Helper Image Container ****** */}
                      <Row className="mb-3">
                        <div className="mb-4">
                          <div
                            style={{
                              width: "100%",
                              textAlign: "left",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={HelperImage}
                              style={{ width: "100%" }}
                              alt=""
                            />
                          </div>
                        </div>
                      </Row>

                      {/* ****** Descriptions Container ****** */}
                      <Row>
                        <div>
                          <p
                            style={{
                              color: "black",
                              fontWeight: "600",
                              textAlign: "start",
                              fontSize: 13,
                            }}
                          >
                            {/* • Open the provided link on a device other than the
                            one you wish to insure (जिस डिवाइस का आप बीमा कराना
                            चाहते हैं उसके अलावा किसी अन्य डिवाइस पर दिए गए लिंक
                            को ट्रांसफर करें और खोलें). */}
                            • Open the provided link on a device other than the
                            one you wish to protect. (प्रदान किए गए लिंक को उस
                            डिवाइस के अलावा किसी अन्य डिवाइस पर खोलें जिसे आप
                            सुरक्षित करना चाहते हैं।)
                          </p>

                          <p
                            style={{
                              color: "black",
                              fontWeight: "600",
                              textAlign: "start",
                              fontSize: 13,
                            }}
                          >
                            • Fill in the required fields and then select the
                            “device image upload” option (आवश्यक विवरण भरें और
                            फिर "डिवाइस छवि अपलोड" विकल्प चुनें).
                          </p>

                          <p
                            style={{
                              color: "black",
                              fontWeight: "600",
                              textAlign: "start",
                              fontSize: 13,
                            }}
                          >
                            {/* • Take the mobile device you wish to insure and dial
                            *#06# to check the IMEI details of your device (वह
                            मोबाइल डिवाइस लें जिसका आप बीमा कराना चाहते हैं और
                            अपने मोबाइल का IMEI विवरण जांचने के लिए *#06# डायल
                            करें). */}
                            • Take the mobile device you wish to protect and
                            dial *#06# to check the IMEI details of your device.
                            (जिस मोबाइल डिवाइस को आप सुरक्षित करना चाहते हैं,
                            उसे लें और *#06# डायल करके अपने डिवाइस का IMEI विवरण
                            जांचें। )
                          </p>

                          <p
                            style={{
                              color: "black",
                              fontWeight: "600",
                              textAlign: "start",
                              fontSize: 13,
                            }}
                          >
                            {/* • Use the other mobile device to click a clear
                            picture of the insured device’s screen displaying
                            the IMEI details and upload the image. Kindly note
                            that screenshots will not be considered (IMEI विवरण
                            प्रदर्शित करने वाले बीमाकृत डिवाइस की स्क्रीन की
                            स्पष्ट तस्वीर क्लिक करने और छवि अपलोड करने के लिए
                            अन्य मोबाइल डिवाइस का उपयोग करें। कृपया ध्यान दें कि
                            स्क्रीनशॉट मान्य नहीं होंगे). */}
                            •  Use the other mobile device to click a clear
                            picture of the protected device screen displaying
                            the IMEI details and upload the image. Kindly note
                            that screenshots will not be considered (दूसरे
                            मोबाइल डिवाइस का उपयोग करके उस स्क्रीन की एक स्पष्ट
                            तस्वीर लें, जिस पर सुरक्षित किए गए डिवाइस का IMEI
                            विवरण दिख रहा हो, और उस चित्र को अपलोड करें। कृपया
                            ध्यान दें कि स्क्रीनशॉट को मान्य नहीं माना जाएगा। )
                          </p>
                        </div>
                      </Row>

                      {/* ****** Device Photo Container ****** */}
                      <Row className="mb-3">
                        <div className="form-group mb-3">
                          <div
                            class="col-xxl-1 col-lg-1 col-md-1 col-sm-1 col-1"
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            <label>
                              Upload Screen Image{" "}
                              <label style={{ color: "red" }}>*</label>
                            </label>
                          </div>

                          <input
                            type="file"
                            class="form-control"
                            name="devicePhoto"
                            accept="image/*"
                            onChange={onChangeDevicePhoto}
                          />
                        </div>
                      </Row>

                      {/* ****** Button Container ****** */}
                      <Button
                        className="w-100 mt-3"
                        type="submit"
                        // onClick={() => navigate("/statusPage")} // Remove this Line for Production
                      >
                        Upload
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Apb;
